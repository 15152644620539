import { userAxios } from '@/services'

const diagnosticImageList = async (perPage) => {
    try {
        return await userAxios.get(`diagnostic_imagin/index/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const diagnosticImageCreate = async (data) => {
    try {
        return await userAxios.post('diagnostic_imagin', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const diagnosticImageUpdate = async (id, data) => {
    try {
        return await userAxios.put(`diagnostic_imagin/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const diagnosticImageDelete = async (id) => {
    try {
        return await userAxios.delete(`diagnostic_imagin/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const diagnosticImagePagination = async (perPage, page) => {
    try {
        return await userAxios.get(`diagnostic_imagin/index/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const diagnosticImageFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`diagnostic_imagin/filter/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const diagnosticImageFilterPagination = async (perPage, page, data ) => {
    try {
        return await userAxios.post(`diagnostic_imagin/filter/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

export default {
    diagnosticImageList,
    diagnosticImageCreate,
    diagnosticImageUpdate,
    diagnosticImageDelete,
    diagnosticImagePagination,
    diagnosticImageFilter,
    diagnosticImageFilterPagination
}